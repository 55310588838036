<template>
  <div v-if="data && data.length">
    <div class="h c sb app-mall-home-title" style="margin-bottom: 10px;">
      <b class="fas fa-bars">&nbsp;组合销售</b>
      <el-button type="text" size="mini" @click="handleList">
        更多&nbsp;
        <i class="el-icon-d-arrow-right"></i>
      </el-button>
    </div>
    <div class="h wrap">
      <group-item v-for="g in data" :key="g.id" :group="g" size="190px" />
    </div>
  </div>
</template>

<script>
import GroupItem from "../assembly/group-item";
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  components: { GroupItem },
  props: {
    supplier: String | Number
  },
  watch: {
    supplier: "toQuery"
  },
  methods: {
    beforeInit(node) {
      this.url = "api/promotion/groupSale";
      this.params.sellerId = this.supplier;
      this.size = 20;
      return true;
    },
    handleList() {
      this.$router.push({
        path: "/mall/group/list"
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>