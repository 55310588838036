<template>
  <div>
    <div class="h c sb app-mall-home-title" style="margin-bottom: 10px;">
      <b class="fas fa-bars">&nbsp;商品列表</b>
      <el-button type="text" size="mini" @click="handleList">
        更多&nbsp;
        <i class="el-icon-d-arrow-right"></i>
      </el-button>
    </div>
    <div class="h wrap">
      <goods-item v-for="g in data" :key="g.id" :goods="g" size="190px" />
    </div>
  </div>
</template>

<script>
import GoodsItem from "../assembly/goods-item";
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  components: {  GoodsItem },
  props: {
    supplier: String | Number
  },
  watch: {
    supplier: "toQuery"
  },
  methods: {
    beforeInit(node) {
      this.url = "api/mall/goods";
      this.params.sellerId = this.supplier;
      this.size = 20;
      return true;
    },
    handleList() {
      this.$router.push({
        path: "/mall/list"
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>