<template>
  <div class="app-mall-goods-item" :style="{width: size}" v-if="goods" @click.stop="handleGoodsDetail">
    <div class="thumbnail bc-l" :style="{backgroundImage: backgroundImageUrl, height: size}"></div>
    <!-- <div class="padding-0-10"><span class="price fc-e">{{(goods.price / 100).toFixed(2)}}</span><span class="fc-g">起</span></div> -->
    <div class="padding-0-10 name">{{goods.name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    goods: Object,
    size: String
  },
  computed: {
    backgroundImageUrl() {
      if (this.goods && this.goods.coverImg) {
        return `url('${this.goods.coverImg}?x-oss-process=image/resize,l_256')`;
      } else {
        return `url('/static/img/no-image.png')`;
      }
    }
  },
  methods: {
    handleGoodsDetail() {
      this.$router.push({
        path: "/mall/item/" + btoa(this.goods.id)
      });
    }
  }
};
</script>