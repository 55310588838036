<template>
  <div class="app-mall-history">
    <div class="padding-10 title">
      <b>最近浏览</b>
    </div>
    <template v-if="calcedStore && calcedStore.length">
      <div class="v padding-05">
        <template v-for="(g, i) in calcedStore">
          <div :key="g.id" class="item padding-05 h s" @click="handleDetail(g.id)">
            <div class="thumbnail" :style="{backgroundImage: `url(${g.coverImg})`}"></div>
            <div class="flex">
              <div class="name">{{g.name}}</div>
              <!-- <div><span class="fc-e price">{{(g.price / 100).toFixed(2)}}</span><span class="fc-g">起</span></div> -->
            </div>
          </div>
        </template>
      </div>
    </template>
    <div class="padding-20 ta-c fc-g" v-else>最近没有浏览任何商品哦~</div>
  </div>
</template>

<script>
export default {
  props: {
    exclude: String | Number,
    supplier: String | Number,
    size: {
      type: Number,
      default: 10
    }
  },
  watch: {
    supplier: "load"
  },
  data() {
    return {
      store: []
    };
  },
  computed: {
    calcedStore() {
      let s = this.exclude
        ? this.store.filter(o => {
            return o.id !== this.exclude;
          })
        : this.store;
      if (s.length > this.size) s.length = this.size;
      return s;
    }
  },
  methods: {
    load() {
      this.store = window.mall.history.get();
    },
    handleDetail(id) {
      this.$router.push({
        path: "/mall/item/" + btoa(id)
      });
    }
  },
  mounted() {
    this.load();
  },
  activated() {
    this.load();
  }
};
</script>