<template>
  <div class="app-mall-goods-item" :style="{width: size}" v-if="group" @click.stop="handleGroupDetail">
    <div class="thumbnail bc-l" :style="{backgroundImage: `url(${group.coverImg || '/static/img/no-image.png'})`, height: size}"></div>
    <div class="padding-0-10">
      <el-tag size="mini">{{methods[group.method]}}</el-tag>
    </div>
    <div class="padding-0-10 name">{{group.name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    group: Object,
    size: String
  },
  data() {
    return {
      methods: {
        discount: "打折",
        save: "减价",
        price: "一口价"
      }
    };
  },
  methods: {
    handleGroupDetail() {
      this.$router.push({
        path: "/mall/group/item/" + btoa(this.group.id)
      });
    }
  }
};
</script>