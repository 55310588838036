<template>
  <div class="container app-mall-home">
    <div class="content h s">
      <div class="left-column">
        <category :supplier="supplier" />
        <history :supplier="supplier" style="margin-top: 10px;" />
      </div>
      <div class="flex">
        <div class="padding-20 h" style="margin: 10px 0; background-color: #ecf5ff;">
          <div class="flex">
            <div class="h c">
              <div class="avatar" :style="{width: avatarSize, height: avatarSize, backgroundImage: user.avatar ? `url(${user.avatar})` : ''}"></div>
              <div class="padding-0-10">
                <div class="fs-large">{{user.userRealName}}</div>
                <div class="fs-small fc-g">{{user.entName}}</div>
              </div>
            </div>
            <div class="h sb" style="margin-top: 10px;">
              <div class="v c m" @click="handleOrderList(0)" style="cursor: pointer;">
                <span class="fs-huge fc-p">{{analysisResult.uncheckNumber}}</span>
                <span class="fc-g">待确认</span>
              </div>
              <div class="v c m" @click="handleOrderList(1)" style="cursor: pointer;">
                <span class="fs-huge fc-p">{{analysisResult.unsendNumber}}</span>
                <span class="fc-g">待发货</span>
              </div>
              <div class="v c m" @click="handleOrderList(2)" style="cursor: pointer;">
                <span class="fs-huge fc-p">{{analysisResult.sendedNumber}}</span>
                <span class="fc-g">待收货</span>
              </div>
              <div class="v c m" @click="handleOrderList(3)" style="cursor: pointer;">
                <span class="fs-huge fc-p">{{analysisResult.unpayNumber}}</span>
                <span class="fc-g">待付款</span>
              </div>
            </div>
          </div>
          <div class="sep"></div>
          <div class="v sb">
            <div class="h e">
              <div class="v c padding-0-10 flex fc-g">
                <i class="fas fa-shopping-cart" style="line-height: 1.8;"></i>
                <span>订货</span>
              </div>
              <div class="v c" style="width: 200px;">
                <span class="fs-large fc-p">￥{{(analysisResult.weekOrderMoney / 100).toFixed(2)}}</span>
                <span class="fc-g">本周</span>
              </div>
              <div class="v c" style="width: 200px;">
                <span class="fs-large fc-p">￥{{(analysisResult.monthOrderMoney / 100).toFixed(2)}}</span>
                <span class="fc-g">本月</span>
              </div>
            </div>

            <div class="h e" v-if="analysisResult.weekPayMoney != null && analysisResult.monthPayMoney != null">
              <div class="v c padding-0-10 flex fc-g">
                <i class="fas fa-comment-dollar" style="line-height: 1.8;"></i>
                <span>付款</span>
              </div>
              <div class="v c" style="width: 200px;">
                <span class="fs-large fc-p">￥{{(analysisResult.weekPayMoney / 100).toFixed(2)}}</span>
                <span class="fc-g">本周</span>
              </div>
              <div class="v c" style="width: 200px;">
                <span class="fs-large fc-p">￥{{(analysisResult.monthPayMoney / 100).toFixed(2)}}</span>
                <span class="fc-g">本月</span>
              </div>
            </div>
          </div>
        </div>
        <group-sale-list :supplier="supplier" />
        <goods-list :supplier="supplier" />
      </div>
    </div>
  </div>
</template>

<script>
import Category from "./assembly/category";
import History from "./assembly/history";
import GoodsList from "./home/goods";
import GroupSaleList from "./home/group-sale";
import { mapGetters } from "vuex";
import request from "@/utils/request";

export default {
  components: { Category, GoodsList, GroupSaleList, History },
  props: {
    supplier: Number | String,
  },
  watch: {
    supplier: "analysis",
  },
  data() {
    return {
      avatarSize: "48px",
      analysisResult: {
        monthOrderMoney: 0,
        monthPayMoney: null,
        sendedNumber: 0,
        uncheckNumber: 0,
        unpayNumber: 0,
        unsendNumber: 0,
        weekOrderMoney: 0,
        weekPayMoney: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    analysis() {
      if (this.supplier) {
        request({
          url: "api/orderForm/shopMain/analy",
          method: "post",
          data: {
            sellerId: this.supplier,
          },
        }).then((res) => {
          this.analysisResult = res;
        });
      }
    },
    handleOrderList(type) {
      this.$router.push({
        path: "/mall/order?t=" + type,
      });
    },
  },
  activated() {
    this.analysis();
  },
};
</script>